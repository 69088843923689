import type { TablePaginationConfig, TableProps } from 'antd'
import { Button, Empty, message, Pagination, Spin, Table, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { BsGrid, BsList } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { formatTime } from '@/common/utils'
import Breadcrumbs from '@/components/Breadcrumb'
import type { RootState } from '@/store'

import ItemsCard from './components/ItemsCard'
import ModalCreateProject from './components/ModalCreateProject'
import SearchProjects from './components/SearchProjects'
import SortProject from './components/SortProject'
import { fetchProjects, setIsGridAllowed, updateFilter } from './reducers/projectsReducers'

interface DataType {
  id: string | number
  name: string
  apiKey: string
  userId: number
  createdAt: string
}

const ProjectIndex = () => {
  const dispatch = useDispatch<any>()
  const navigate: NavigateFunction = useNavigate()
  const { total, items, filter } = useSelector((state: RootState) => state.projects)
  const isGridAllowed = useSelector((state: RootState) => state.typeGrid.isGridAllowed)
  const [messageApi, contextHolder] = message.useMessage()
  const [loading, setLoading] = useState(false)

  const getAllProjects = async () => {
    try {
      setLoading(true)
      await dispatch(fetchProjects()).unwrap()
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getAllProjects()
  }, [filter])

  const onChangePage = async (page: number, pageSize?: number) => {
    const newFilter = {
      ...filter,
      page,
      ...(pageSize && { limit: pageSize })
    }
    await dispatch(updateFilter(newFilter))
  }

  const onChangePageTable = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
    try {
      setLoading(true)
      let newFilter = {}
      if (sorter.field && sorter.order) {
        newFilter = Object.assign(newFilter, {
          sorts: sorter.order === 'ascend' ? `${sorter.field}` : `-${sorter.field}`
        })
      }
      if (pagination?.current) {
        newFilter = Object.assign(newFilter, {
          page: pagination.current
        })
      }
      await dispatch(updateFilter(newFilter))
      await getAllProjects()
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const breadcrumbItems = [
    { title: 'Home', url: '/' },
    { title: 'Projects', url: '/Projects' }
  ]

  const handleShowProject = () => {
    dispatch(setIsGridAllowed(!isGridAllowed))
  }

  /**
   * Handle table
   */

  const handleCopyApiKey = (key: any) => {
    try {
      if (key) {
        navigator.clipboard.writeText(key)
        messageApi.success('API Key copied to clipboard!')
      }
    } catch (error) {}
  }

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      fixed: 'left'
    },
    {
      title: 'API Key',
      dataIndex: 'apiKey',
      key: 'apiKey',
      width: '30%',
      className: 'column-api-key',
      render: (val: string) => (
        <div onClick={() => handleCopyApiKey(val)}>
          <Tooltip title='Copy Api Key'>
            {val.slice(0, 10)} ... {val.slice(-10)}
          </Tooltip>
        </div>
      )
    },
    {
      title: 'Date Create',
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: '20%',
      render: (val: string) => <div>{formatTime(new Date(val))}</div>
    }
  ]

  return (
    <div className='h-dvh flex flex-col gap-5'>
      {contextHolder}
      <Breadcrumbs items={breadcrumbItems} />
      <div className='flex w-full flex-col gap-5 rounded bg-white p-5'>
        <div className='flex flex-row justify-between'>
          <div className='text-lg font-bold'>Project Manager</div>
          <ModalCreateProject />
        </div>
        <div className='flex flex-row items-center justify-between'>
          <div className='flex flex-row justify-end'>
            <div className='flex flex-row gap-2'>
              <Button className='px-5' onClick={handleShowProject} icon={<BsGrid size={18} />} type={!isGridAllowed ? 'primary' : 'default'} />
              <Button className='px-5' onClick={handleShowProject} icon={<BsList size={18} />} type={isGridAllowed ? 'primary' : 'default'} />
            </div>
          </div>
          <div className='flex flex-row justify-end gap-5'>
            <SortProject />
            <SearchProjects />
          </div>
        </div>
        {!isGridAllowed ? (
          <div>
            {loading ? (
              <div className='flex w-full flex-row justify-center'>
                <Spin size='large' />
              </div>
            ) : (
              <div className='flex w-full flex-col gap-5 '>
                {items.length > 0 ? (
                  <>
                    <div className='grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4'>
                      {items.map((item: any) => (
                        <ItemsCard key={item.id} id={item.id} name={item.name} createAt={item.createdAt} />
                      ))}
                    </div>
                    <div className='flex w-full justify-center p-5'>
                      <Pagination onChange={onChangePage} showSizeChanger defaultCurrent={filter.page} total={total} pageSize={filter.limit} />
                    </div>
                  </>
                ) : (
                  <div className='flex w-full justify-center'>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div>
            <Table
              onRow={(record) => {
                return {
                  onClick: (event) => {
                    const columnClass = (event.target as Element).closest('td')?.classList
                    if (!columnClass?.contains('column-api-key')) {
                      navigate(`/projects/${record.id}/upload`)
                    }
                  }
                }
              }}
              columns={columns}
              rowKey='id'
              rowClassName='cursor-pointer'
              dataSource={items}
              pagination={{ pageSize: filter.limit, current: filter.page, total }}
              showSorterTooltip={false}
              loading={loading}
              onChange={onChangePageTable}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default ProjectIndex
