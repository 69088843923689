import { FileOutlined } from '@ant-design/icons'
import { Image, Tooltip } from 'antd'
import React from 'react'

type Props = {
  name: string
  type: string
  url: string
}

const ItemsCardUpload = ({ name, type, url }: Props) => {
  return (
    <Tooltip title={name}>
      <div className='w-full cursor-pointer overflow-hidden rounded-lg bg-gray-100 p-1 shadow hover:shadow-md'>
        <div className='m-0 w-full p-1'>
          <div className='flex h-[150px] w-full items-center justify-center overflow-hidden'>
            {type === 'image' ? (
              <div className='customImgaeGrid'>
                <Image className='h-full w-full rounded object-contain' src={url} preview={false} />
              </div>
            ) : (
              <div className='flex w-full items-center justify-center opacity-60'>
                <FileOutlined className='text-[100px]' />
              </div>
            )}
          </div>
        </div>
      </div>
    </Tooltip>
  )
}

export default ItemsCardUpload
