import type { TablePaginationConfig, TableProps } from 'antd'
import { Drawer, Table } from 'antd'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import { EUserStatus } from '@/common/enum'
import { formatTime } from '@/common/utils'
import Breadcrumbs from '@/components/Breadcrumb'
import type { RootState } from '@/store'

import ButtonDelete from './components/ButtonDelete'
import ModalCreate from './components/ModalCreate'
import SearchUsers from './components/Search'
import UserStatus from './components/UserStatus'
import { fetchUsers, updateFilter } from './reducers/userReducer'

interface DataType {
  id: string | number
  name: string
  username: string
  email: number
  createdAt: string
  status: string[]
  permissions: number[]
}

const Users = () => {
  const navigate: NavigateFunction = useNavigate()
  const params = useParams()
  const { items, total, filter } = useSelector((state: RootState) => state.user)
  const dispatch = useDispatch<any>()
  const [selectId, setSelectedId] = useState('')
  const [loadingTable, setLoadingTable] = useState(false)

  const onClickRow = (record: any) => {
    setSelectedId(record.id)
    navigate(`/users/${record.id}`)
  }

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      fixed: 'left'
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      width: '22%'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '30%'
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: '8%',
      filters: [
        { text: 'Inactive', value: EUserStatus.INACTIVE },
        { text: 'Active', value: EUserStatus.ACTIVE },
        { text: 'Spam', value: EUserStatus.SPAM },
        { text: 'Banned', value: EUserStatus.BANNED },
        { text: 'Trash', value: EUserStatus.TRASH }
      ],
      render: (val: number) => <UserStatus status={val} />
    },
    {
      title: 'Date Create',
      key: 'createdAt',
      dataIndex: 'createdAt',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'],
      width: '20%',
      render: (val: string) => <div>{formatTime(new Date(val))}</div>,
      fixed: 'right'
    }
  ]

  const fetchAllUsers = async () => {
    try {
      setLoadingTable(true)
      await dispatch(fetchUsers()).unwrap()
    } catch (error) {
    } finally {
      setLoadingTable(false)
    }
  }

  useEffect(() => {
    fetchAllUsers()
  }, [filter])

  const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
    try {
      setLoadingTable(true)
      let newFilter = {}
      if (sorter.field && sorter.order) {
        newFilter = Object.assign(newFilter, {
          sorts: sorter.order === 'ascend' ? `${sorter.field}` : `-${sorter.field}`
        })
      }

      // filters
      if (!isEmpty(filters.status)) {
        newFilter = Object.assign(newFilter, {
          status: `in:${filters.status.join(',')}`,
          page: 1
        })
      } else if (filters.status === null) {
        newFilter = Object.assign(newFilter, {
          status: undefined,
          page: 1
        })
      }

      if (pagination?.current) {
        newFilter = Object.assign(newFilter, {
          page: pagination.current
        })
      }
      await dispatch(updateFilter(newFilter))
    } catch (error) {
    } finally {
      setLoadingTable(false)
    }
  }

  // detail
  const shouldOpen = () => {
    if (params.id) {
      return true
    }
    return false
  }

  const handleClose = () => {
    navigate(`/users`)
  }

  const breadcrumbItems = [
    { title: 'Home', url: '/' },
    { title: 'Users', url: '/users' }
  ]

  return (
    <div className='h-dvh flex flex-col gap-5'>
      <Breadcrumbs items={breadcrumbItems} />
      <div className='flex flex-col gap-5 rounded bg-white p-4'>
        <div className='text-lg font-bold'>User Manager</div>
        <div className='flex flex-row items-center justify-between'>
          <div className='flex flex-row justify-end'>
            <SearchUsers />
          </div>
          <div className='flex flex-row justify-end'>
            <ModalCreate />
          </div>
        </div>
        <Table
          onRow={(record) => {
            return {
              onClick: () => {
                onClickRow(record)
              }
            }
          }}
          columns={columns}
          rowKey='id'
          rowClassName='cursor-pointer'
          dataSource={items}
          pagination={{ pageSize: filter.limit, current: filter.page, total }}
          showSorterTooltip={false}
          loading={loadingTable}
          onChange={handleTableChange}
        />
      </div>
      <Drawer title='Edit User' width={500} placement='right' onClose={handleClose} destroyOnClose open={shouldOpen()}>
        <div className='flex h-full flex-col justify-between'>
          {shouldOpen() && <Outlet />}
          <ButtonDelete userId={selectId} />
        </div>
      </Drawer>
    </div>
  )
}

export default Users
