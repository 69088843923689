import type { PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

import type { RootState } from '@/store'

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

api.interceptors.response.use(
  (response) => {
    return response.data
  },
  async (err) => {
    return Promise.reject(err)
  }
)

export interface AuthState {
  isLoggedIn: boolean
  token: string
  refreshToken: string
  user: any
  isAdmin: boolean
}

const initialState: AuthState = {
  isLoggedIn: false,
  token: '',
  refreshToken: '',
  user: undefined,
  isAdmin: false
}

/*
 *****************************************
 *
 *
 */

interface LoginPayloadDto {
  email: string
  password: string
}

export const login = createAsyncThunk('auth/login', async (data: LoginPayloadDto, { rejectWithValue }) => {
  try {
    let token = ''
    let refreshToken = ''

    const rs = (await api.post('auth/login', {
      email: data.email,
      password: data.password
    })) as any

    token = rs.token
    refreshToken = rs.refreshToken

    if (token) {
      const user = await api.get('me', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })

      return { token, refreshToken, user }
    }

    return { token, refreshToken }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

export const register = createAsyncThunk('auth/register', async (data: any, { rejectWithValue }) => {
  try {
    const { username, email, password } = data

    const response = await api.post('auth/register', {
      username,
      email,
      password
    })

    return response.data
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const confirmEmail = createAsyncThunk('auth/register/confirm', async (data: any, { rejectWithValue }) => {
  try {
    const { token } = data

    const response = await api.post('auth/register/confirm', {
      token
    })

    return response.data
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 ***********
 * Reset Password
 *
 */
export const requestResetPassword = createAsyncThunk('auth/reset-password/request', async (data: any, { rejectWithValue }) => {
  try {
    const { email } = data

    const response = await api.post('auth/reset-password/request', {
      email
    })

    return response.data
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const resetPassword = createAsyncThunk('auth/reset-password', async (data: any, { rejectWithValue }) => {
  try {
    const { password, token } = data

    const response = await api.post('auth/reset-password', {
      password,
      token
    })

    return response.data
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const refreshAccessToken = createAsyncThunk('auth/refreshAccessToken', async (_, thunkAPI) => {
  const state = thunkAPI.getState() as RootState

  try {
    return await api.post('auth/refresh', {
      refreshToken: state.auth.refreshToken
    })
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
})
/*
 *****************************************
 * authSlice
 *
 */
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.token = ''
      state.refreshToken = ''
      state.user = null
      state.isLoggedIn = false
      localStorage.removeItem('persist:root')
    },

    logout: (state) => {
      state.isAdmin = false
      state.token = ''
      state.refreshToken = ''
      state.user = null
      state.isLoggedIn = false
      localStorage.clear()
    },

    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload
    },

    setIsAdmin: (state) => {
      state.isAdmin = true
    },

    updateAuthUser: (state, action: PayloadAction<any>) => {
      state.user = Object.assign(state.user, action.payload)
    },

    updateToken: (state, action: PayloadAction<any>) => {
      state.token = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        const { token, refreshToken, user } = action.payload
        state.token = token
        state.refreshToken = refreshToken
        state.user = user
        state.isLoggedIn = true
        if (state.user?.permissions[0] === 9999) {
          state.isAdmin = true
        }
      })
      .addCase(register.fulfilled, (state, action) => {
        state.token = action.payload
      })
      .addCase(refreshAccessToken.fulfilled, () => {
        // authSlice.reducer(state, { type: 'auth/reset' })
      })
  }
})

export const { setUser, updateAuthUser, reset, updateToken, logout, setIsAdmin } = authSlice.actions
export default authSlice.reducer
