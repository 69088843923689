import { CheckOutlined, CloseOutlined, InboxOutlined, LoadingOutlined } from '@ant-design/icons'
import { Spin, Upload } from 'antd'
import { random } from 'lodash'
import React, { useState } from 'react'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { randomString } from '@/common/utils'

import { uploadFile } from '../reducers/assetsReducers'

const { Dragger } = Upload

const UploadFile: React.FC = () => {
  const dispatch = useDispatch<any>()
  const params = useParams()
  const [fileList, setFileList] = useState<any[]>([])
  const [uploadedFiles, setUploadedFiles] = useState<{ name: string; id: string; status: string }[]>([])
  const [showUploadedFiles, setShowUploadedFiles] = useState<boolean>(false)
  const [showDetailsUpload, setShowDetailsUpload] = useState<boolean>(true)

  const handleRemove = (file: any) => {
    const updatedList = fileList.filter((item) => item.uid !== file.uid)
    setFileList(updatedList)
  }

  const handleCustomRequest = async ({ file, onSuccess, onError }: any) => {
    const formData = new FormData()
    formData.append('file', file)
    const notificationKey = random(0, 1000000) + randomString(5)

    setUploadedFiles((prev) => [...prev, { name: file.name, id: notificationKey, status: 'loading' }])
    setShowUploadedFiles(true)
    try {
      const response = await dispatch(uploadFile({ projectId: params.id ?? '', formData })).unwrap()

      if (response) {
        onSuccess('ok')
        setUploadedFiles((prev) => prev.map((item) => (item.id === notificationKey ? { ...item, status: 'success' } : item)))
      } else {
        onError('error')
        setUploadedFiles((prev) => prev.map((item) => (item.id === notificationKey ? { ...item, status: 'error' } : item)))
      }
    } catch (error) {
      onError(error)
      setUploadedFiles((prev) => prev.map((item) => (item.id === notificationKey ? { ...item, status: 'error' } : item)))
    }
  }

  const toggleShowUploadFiles = () => {
    setShowUploadedFiles((prev) => !prev)
    setUploadedFiles([])
  }

  const toggleShowDetailsUpload = () => {
    setShowDetailsUpload((prev) => !prev)
  }

  return (
    <>
      <Dragger className='w-full bg-white' multiple customRequest={handleCustomRequest} onRemove={handleRemove} fileList={fileList} showUploadList={false}>
        <p className='ant-upload-drag-icon'>
          <InboxOutlined />
        </p>
        <p className='ant-upload-text'>Click or drag file to this area to upload</p>
        <p className='ant-upload-hint'>Support for a single or bulk upload. Strictly prohibited from uploading company data or other banned files.</p>
      </Dragger>

      {showUploadedFiles && (
        <div id='uploaded-files' className='fixed bottom-0 right-5 z-10 w-[360px] overflow-hidden rounded-t-lg bg-white shadow-lg'>
          <div className='flex h-[52px] w-full flex-row items-center justify-between bg-[#f8fafd] px-3'>
            <h3 className='text-base font-bold'>Upload {uploadedFiles.length} file</h3>
            <div className='flex flex-row items-center gap-2'>
              <button onClick={toggleShowDetailsUpload} className='cursor-pointer rounded-[50%] bg-[#f8fafd] p-1 hover:bg-gray-100'>
                {showDetailsUpload ? <IoIosArrowDown size={18} /> : <IoIosArrowUp size={18} />}
              </button>
              <button onClick={toggleShowUploadFiles} className='cursor-pointer rounded-[50%] bg-[#f8fafd] p-1 hover:bg-gray-100'>
                <CloseOutlined size={18} />
              </button>
            </div>
          </div>

          {showDetailsUpload && (
            <div className='flex max-h-[250px] min-h-[50px] w-full flex-col gap-2 overflow-x-hidden overflow-y-scroll px-1 py-2'>
              {uploadedFiles.length > 0 ? (
                uploadedFiles.map((file) => (
                  <div className='px-[10px]' key={file.id}>
                    <div className='custom-upload-item flex flex-row items-center justify-between'>
                      <span className='mr-[10px]'>{file.name.length > 30 ? `${file.name.slice(0, 15)}...${file.name.slice(-10)}` : file.name}</span>
                      <div className='flex w-[70px] flex-row items-center justify-center gap-2'>
                        {file.status === 'loading' && (
                          <div className='flex items-center justify-center '>
                            <Spin indicator={<LoadingOutlined spin />} />
                          </div>
                        )}
                        {file.status === 'success' && (
                          <span className='text-green-500'>
                            <CheckOutlined />
                          </span>
                        )}
                        {file.status === 'error' && (
                          <span className='text-danger'>
                            <CloseOutlined />
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No files uploaded</p>
              )}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default UploadFile
