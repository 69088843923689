import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from '@/common/api'
import type { RootState } from '@/store'

interface AssetsState {
  items: any[]
  total: number
  totalPages: number
  filter: {
    page: number
    limit: number
    search?: string
    sorts?: string
    fields?: string
    projectId?: string
  }
  isGridAssetsAllowed: boolean
}

const initialState: AssetsState = {
  items: [],
  total: 0,
  totalPages: 0,
  filter: {
    page: 1,
    limit: 20,
    search: '',
    sorts: '-createdAt',
    fields: undefined,
    projectId: undefined
  },
  isGridAssetsAllowed: false
}

type AssetsProjects = {
  projectId: string
}

export const getAssetsByProjectId = createAsyncThunk('project/assets/list', async ({ projectId }: AssetsProjects, { getState, rejectWithValue }) => {
  try {
    const state = getState() as RootState
    const params = { ...state.assetsProjects.filter, projectId }
    if (params.search === '') {
      delete params.search
    }
    if (params.fields === '') {
      delete params.fields
    }
    return await api.get(`assets`, { params })
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const uploadFile = createAsyncThunk(
  'projects/assets/upload',
  async ({ projectId, formData }: { projectId: string; formData: FormData }, { rejectWithValue }) => {
    try {
      const rs: any = await api.post(`/projects/${projectId}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return rs
    } catch (error: any) {
      return rejectWithValue(error?.response?.data)
    }
  }
)

type DeleteFileTodo = {
  id: string
}

export const deleteFile = createAsyncThunk('delete/assets', async (payload: DeleteFileTodo, { rejectWithValue }) => {
  try {
    const rs = await api.delete(`/assets/${payload.id}`)
    return { id: payload.id, status: rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const assetsSlice = createSlice({
  name: 'cloud_admin_assets',
  initialState,
  reducers: {
    reset: (state) => {
      state.total = initialState.total
      state.items = initialState.items
      state.filter = JSON.parse(JSON.stringify(initialState.filter))
    },
    updateFilter(state, { payload }) {
      state.filter = { ...state.filter, ...payload }
    },
    setIsGridAllowed: (state, action) => {
      state.isGridAssetsAllowed = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAssetsByProjectId.fulfilled, (state, action) => {
        const { total, items } = action.payload as any
        state.items = items
        state.total = total
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        const info = action.payload
        const index = state.items.findIndex((item) => item.id === info.id)
        if (index > 0) {
          const newObject = Object.assign(state.items[index], info)
          state.items[index] = newObject
        } else {
          state.items.unshift(info)
          state.total += 1
        }
      })
      .addCase(deleteFile.fulfilled, (state, action) => {
        const { id } = action.payload
        const index = state.items.findIndex((item) => item.id === id)
        if (index >= 0) {
          state.items.splice(index, 1)
          state.total -= 1
        }
      })
  }
})

export const { reset, updateFilter, setIsGridAllowed } = assetsSlice.actions
export default assetsSlice.reducer
